import React from "react";
import { Helmet } from "react-helmet";
import Locale from "../../types/Locale";

interface Props {
  locale?: Locale;
}

const GlobalHead: React.FC<Props> = ({ locale = Locale.en }) => (
  <Helmet>
    <html lang={locale} />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="robots" content="noindex" />
    <link
      href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap"
      rel="stylesheet"
    />
  </Helmet>
);

export default GlobalHead;
