import React, { useContext } from "react";
import { unflatten } from "flat";
import { useIntl, FormattedMessage } from "react-intl";
import ImagesContext from "../../helpers/ImagesContext";
import {
  Wrapper,
  Logo,
  SocialLogo,
  Social,
  Column,
  Link,
  Separator,
  ExternalLink,
} from "./styled";
import LinkedinBrands from "../SVGs/LinkedinBrands";
import FacebookSquareBrands from "../SVGs/FacebookSquareBrands";
import Img from "../Img";

interface Props {
  index?: boolean;
}

const Footer: React.FC<Props> = ({ index }) => {
  const images = useContext(ImagesContext);
  const { messages, locale: currentLocale } = useIntl();
  const content = unflatten(messages);

  const logoImage =
    images.find((i) => content.common.footer.image.src === i.file) || null;

  return (
    <Wrapper>
      <Logo>
        <Img data={logoImage} alt={content.common.footer.image.alt} />
      </Logo>
      <Column>
        <div>
          <Link
            to={
              index === true
                ? content.common.footer.about.href
                : `/${currentLocale}/${content.common.footer.about.href}`
            }
          >
            <FormattedMessage id="common.footer.about.text" />
          </Link>
        </div>
        <Separator />
        <div>
          {content.common.footer.about.links.map((l, i) => (
            <Link
              to={index === true ? l.href : `/${currentLocale}/${l.href}`}
              key={i}
            >
              <FormattedMessage id={`common.footer.about.links.${i}.text`} />
            </Link>
          ))}
        </div>
      </Column>
      <Column>
        <div>
          <ExternalLink href={content.common.footer.account.href}>
            <FormattedMessage id="common.footer.account.text" />
          </ExternalLink>
        </div>
        <Separator />
        <div>
          {content.common.footer.account.links.map((l, i) => (
            <ExternalLink href={l.href} key={i}>
              <FormattedMessage id={`common.footer.account.links.${i}.text`} />
            </ExternalLink>
          ))}
        </div>
      </Column>
      <Column>
        <div>
          <Link
            to={
              index === true
                ? content.common.footer.contact.href
                : `/${currentLocale}/${content.common.footer.contact.href}`
            }
          >
            <FormattedMessage id="common.footer.contact.text" />
          </Link>
        </div>
      </Column>
      <Social>
        <SocialLogo
          href={content.common.footer.social.facebook}
          target="_about"
          rel="noopener noreferrer"
        >
          <FacebookSquareBrands />
        </SocialLogo>
        <SocialLogo
          href={content.common.footer.social.linkedin}
          target="_about"
          rel="noopener noreferrer"
        >
          <LinkedinBrands />
        </SocialLogo>
      </Social>
    </Wrapper>
  );
};

export default Footer;
