import React from "react";
import GatsbyImage from "gatsby-image";
import { Image } from "../../helpers/ImagesContext";

interface Props {
  data: Image;
  alt?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  imgStyle?: object;
}

const Img: React.FC<Props> = ({ data, alt, imgStyle }) => (
  <>
    {data !== null && (data.netlify === true || data.extension === "svg") && (
      <img src={data.url} alt={alt} />
    )}
    {data !== null && data.netlify === false && data.extension !== "svg" && (
      <GatsbyImage fluid={data.fluid} alt={alt} imgStyle={imgStyle} />
    )}
  </>
);

export default Img;
