import { createContext } from "react";
import { FluidObject } from "gatsby-image";

export interface Image {
  extension: string;
  file: string;
  url: string;
  fluid: FluidObject | FluidObject[] | null;
  netlify: boolean;
}

const ImagesContext = createContext<Image[]>([]);

export default ImagesContext;
