import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import linkReset from "../../helpers/linkReset";

export const Wrapper = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.footer};
  color: ${({ theme }) => theme.palette.text.contrast};
  padding: ${({ theme }) =>
    `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`};
  box-sizing: border-box;
  justify-content: space-between;
  align-items: stretch;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      padding: ${({ theme }) =>
        `${theme.spacing.unit * 4}px ${theme.spacing.unit * 8}px`};
    }
  `};
`;

export const Logo = styled.div`
  width: 200px;
  flex-shrink: 0;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  display: flex;
  align-items: center;

  & img {
    width: 100%;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      width: 280px;
      margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
      margin-bottom: 0;
    }
  `};
`;

export const Social = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const SocialLogo = styled.a`
  ${linkReset};
  display: flex;
  align-items: center;
  width: 24px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit}px;

  & > svg {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Column = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;

  & > div:first-child > a {
    font-weight: bold;
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      height: 100%;
      margin-bottom: 0;
    }
  `};
`;

export const Link = styled(GatsbyLink)<{
  bold?: boolean;
}>`
  ${linkReset};
  font-weight: ${({ bold = false }) => (bold === true ? "bold" : "normal")};
`;

export const ExternalLink = styled.a<{
  bold?: boolean;
}>`
  ${linkReset};
  font-weight: ${({ bold = false }) => (bold === true ? "bold" : "normal")};
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.primary.default};
  margin: ${({ theme }) => theme.spacing.unit}px 0;
`;
