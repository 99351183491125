import { css } from "styled-components";

const linkReset = css`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: block;
`;

export default linkReset;
