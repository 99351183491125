import styled, { css } from "styled-components";
import { transparentize } from "polished";
import linkReset from "../../helpers/linkReset";

export const Wrapper = styled.header<{ height?: number }>`
  height: 420px;
  position: relative;
  width: 100%;

  & > div:first-child {
    width: 100%;
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
  }

  ${({ theme, height }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      height: ${typeof height === "number" ? `${height}px` : "unset"};
    }
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

export const Circle = styled.div<{ position: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
  width: 230px;
  background-color: ${({ theme }) =>
    transparentize(0.1, theme.palette.primary.default)};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.primary.contrast};
  margin: 0 auto;

  ${({ theme, position }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      height: 280px;
      width: 280px;
      margin-left: ${position};
    }
  `};
`;

export const Title = styled.h1`
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  max-width: 200px;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      font-size: 1.8rem;
      max-width: 240px;
    }
  `};
`;

export const LoginApplyBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.background.footer};
  color: ${({ theme }) => theme.palette.text.contrast};
  text-align: center;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.unit}px
    ${({ theme }) => theme.spacing.unit * 4}px;
  border-radius: ${({ theme }) => theme.border.radius.big}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  & > div {
    width: 80px;
    height: 1px;
    margin: ${({ theme }) => theme.spacing.unit}px 0;
    background-color: ${({ theme }) => theme.palette.text.contrast};
  }

  & > a {
    ${linkReset};
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      right: ${({ theme }) => theme.spacing.unit * 8}px;
      border-bottom-right-radius: ${({ theme }) => theme.border.radius.big}px;
    }
  `};
`;
