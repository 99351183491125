import React from "react";
import { useLocation } from "@reach/router";
import { FormattedMessage } from "react-intl";
import {
  Link,
  Links,
  Languages,
  Language,
  LanguageSeparator,
  Wrapper,
  Logo,
} from "./styled";
import Locale from "../../../types/Locale";
import { Image } from "../../../helpers/ImagesContext";
import Img from "../../Img";

interface Props {
  index?: boolean;
  logoImage: Image;
  currentLocale: Locale;
  redirect: (l: Locale) => void;
}

const Desktop: React.FC<Props> = ({
  index,
  logoImage,
  currentLocale,
  redirect,
}) => {
  const location = useLocation();

  return (
    <Wrapper>
      <Logo href="/">
        <Img data={logoImage} />
      </Logo>
      <Links>
        <Link
          to={index === true ? "#services" : `/${currentLocale}/#services`}
          selected={
            index === true &&
            (location.hash === "#services" || location.hash === "")
          }
        >
          <FormattedMessage id="common.nav.services" />
        </Link>
        <Link
          to={index === true ? "#values" : `/${currentLocale}/#values`}
          selected={index === true && location.hash === "#values"}
        >
          <FormattedMessage id="common.nav.values" />
        </Link>
        <Link
          to={index === true ? "#clients" : `/${currentLocale}/#clients`}
          selected={index === true && location.hash === "#clients"}
        >
          <FormattedMessage id="common.nav.clients" />
        </Link>
        <Link
          to={index === true ? "#team" : `/${currentLocale}/#team`}
          selected={index === true && location.hash === "#team"}
        >
          <FormattedMessage id="common.nav.team" />
        </Link>
        <Link
          to={index === true ? "#about" : `/${currentLocale}/#about`}
          selected={index === true && location.hash === "#about"}
        >
          <FormattedMessage id="common.nav.about" />
        </Link>
        <Link
          to={index === true ? "#contact" : `/${currentLocale}/#contact`}
          selected={index === true && location.hash === "#contact"}
        >
          <FormattedMessage id="common.nav.contact" />
        </Link>
      </Links>
      <Languages>
        <Language
          selected={currentLocale === Locale.nl}
          href={`/nl/${location.pathname.substr(4)}${location.hash}`}
          onClick={(e) => {
            e.preventDefault();
            redirect(Locale.nl);
          }}
        >
          NL
        </Language>
        <LanguageSeparator>|</LanguageSeparator>
        <Language
          selected={currentLocale === Locale.fr}
          href={`/fr/${location.pathname.substr(4)}${location.hash}`}
          onClick={(e) => {
            e.preventDefault();
            redirect(Locale.fr);
          }}
        >
          FR
        </Language>
        <LanguageSeparator>|</LanguageSeparator>
        <Language
          selected={currentLocale === Locale.en}
          href={`/en/${location.pathname.substr(4)}${location.hash}`}
          onClick={(e) => {
            e.preventDefault();
            redirect(Locale.en);
          }}
        >
          EN
        </Language>
        <LanguageSeparator>|</LanguageSeparator>
        <Language
          selected={currentLocale === Locale.de}
          href={`/de/${location.pathname.substr(4)}${location.hash}`}
          onClick={(e) => {
            e.preventDefault();
            redirect(Locale.de);
          }}
        >
          DE
        </Language>
      </Languages>
    </Wrapper>
  );
};

export default Desktop;
