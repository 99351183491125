import { css } from "styled-components";

const buttonReset = css`
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: block;
`;

export default buttonReset;
