import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ImagesDataProps from "../types/ImagesDataProps";
import { Image } from "./ImagesContext";

const useImages = (): Image[] => {
  const data: ImagesDataProps = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          sourceInstanceName: { eq: "assets" }
        }
      ) {
        edges {
          node {
            name
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      svgs: allFile(
        filter: {
          extension: { regex: "/(svg)/" }
          sourceInstanceName: { eq: "assets" }
        }
      ) {
        edges {
          node {
            name
            extension
            publicURL
          }
        }
      }
    }
  `);

  const images = useMemo(
    () => [
      ...data.images.edges.map((i) => ({
        file: `${i.node.name}.${i.node.extension}`,
        extension: i.node.extension,
        url: i.node.publicURL,
        fluid:
          i.node.childImageSharp === null ? null : i.node.childImageSharp.fluid,
        netlify: false,
      })),
      ...data.svgs.edges.map((i) => ({
        file: `${i.node.name}.${i.node.extension}`,
        extension: i.node.extension,
        url: i.node.publicURL,
        fluid: null,
        netlify: false,
      })),
    ],
    [data]
  );

  return images;
};

export default useImages;
