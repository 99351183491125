import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  border: {
    radius: {
      default: 3,
      medium: 5,
      big: 16,
      infinite: 1000,
    },
    solid: {
      default: "1px solid",
      medium: "3px solid",
      big: "5px solid",
    },
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  palette: {
    primary: {
      default: "#d8222a",
      contrast: "#ffffff",
    },
    error: {
      default: "#e02b5b",
    },
    text: {
      default: "#292929",
      alternative: "#666666",
      contrast: "#ffffff",
      disabled: "rgba(0, 0, 0, 0.2)",
    },
    background: {
      default: "#ffffff",
      alternative: "#e4e4e3",
      footer: "#6d6e6d",
      contrast: "#F0F0F0",
    },
    border: {
      default: "#aaaaaa",
    },
  },
  typography: {
    fontFamily: '"Raleway", sans-serif',
  },
  spacing: {
    unit: 8,
  },
};

export default theme;
