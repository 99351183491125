import styled, { css } from "styled-components";

const Container = styled.div<{
  dTop?: number;
  dBottom?: number;
  mTop?: number;
  mBottom?: number;
}>`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.unit * 2}px;
  padding-top: ${({ theme, mTop = null }) =>
    mTop === null ? 0 : `${theme.spacing.unit * mTop}px`};
  padding-bottom: ${({ theme, mBottom = null }) =>
    mBottom === null ? 0 : `${theme.spacing.unit * mBottom}px`};
  box-sizing: border-box;
  margin: 0 auto;

  ${({ theme, dTop = null, dBottom = null }) => css`
    @media screen and (min-width: ${theme.breakpoints.sm}px) {
      max-width: 540px;
    }

    @media screen and (min-width: ${theme.breakpoints.md}px) {
      max-width: 720px;
    }

    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      max-width: 960px;
      padding-top: ${dTop === null ? 0 : `${theme.spacing.unit * dTop}px`};
      padding-bottom: ${dBottom === null
        ? 0
        : `${theme.spacing.unit * dBottom}px`};
    }

    @media screen and (min-width: ${theme.breakpoints.xl}px) {
      max-width: 1140px;
    }
  `};
`;

export default Container;
