import styled, { css } from "styled-components";

const Anchor = styled.div<{ first?: boolean }>`
  visibility: hidden;
  height: 70px;
  margin-top: ${({ first = false }) => (first === true ? 0 : "-70px")};

  ${({ theme, first = false }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      height: 70px;
      margin-top: ${first === true ? 0 : "-70px"};
    }
  `};
`;

export default Anchor;
