import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import linkReset from "../../../helpers/linkReset";
import buttonReset from "../../../helpers/buttonReset";

export const Menu = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show === true ? "block" : "none ")};
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 5;
  text-align: center;
  border-top: ${({ theme }) =>
    `${theme.border.solid.default} ${theme.palette.background.alternative}`};
`;

export const Logo = styled.a`
  ${linkReset};
  width: 200px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const Wrapper = styled.nav`
  width: 100vw;
  height: 70px;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.unit * 2}px;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.06) 0px 3px 6px 0px;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: none;
    }
  `};
`;

export const Links = styled.div`
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const Languages = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const Language = styled.a<{ selected?: boolean }>`
  ${linkReset};
  font-weight: ${({ selected = false }) =>
    selected === true ? "bold" : "normal"};
  color: ${({ theme, selected = false }) =>
    selected === true ? theme.palette.primary.default : "inherit"};
  padding: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const LanguageSeparator = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * 2}px 0;
`;

export const Link = styled(GatsbyLink)`
  ${linkReset};
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing.unit * 2}px 0;
  box-sizing: border-box;
`;

export const Button = styled.button`
  ${buttonReset};

  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.default};

  & > svg {
    width: 1.2rem;
  }
`;
