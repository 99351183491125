import React, { useContext } from "react";
import { Link } from "gatsby";
import { Wrapper, Circle, Title, Overlay, LoginApplyBlock } from "./styled";
import ImagesContext from "../../helpers/ImagesContext";
import Img from "../Img";
import { useIntl } from "react-intl";

interface Props {
  background: string;
  height?: number;
  objectPosition?: string;
  title: string;
  circlePosition?: string;
  loginApply?: {
    login: {
      label: string;
      link: string;
    };
    apply: {
      label: string;
      link: string;
    };
  };
}

const Header: React.FC<Props> = ({
  background,
  height,
  objectPosition = "center center",
  title,
  circlePosition = "15%",
  loginApply = null,
}) => {
  const { locale } = useIntl();
  const images = useContext(ImagesContext);
  const imgData = images.find((i) => i.file === background) || null;

  return (
    <Wrapper height={height}>
      <Img data={imgData} imgStyle={{ objectPosition }} />
      <Overlay>
        <Circle position={circlePosition}>
          <Title>{title}</Title>
        </Circle>
      </Overlay>
      {loginApply !== null && (
        <LoginApplyBlock>
          <a href={loginApply.login.link}>{loginApply.login.label}</a>
          <div />
          <Link to={`/${locale}${loginApply.apply.link}`}>
            {loginApply.apply.label}
          </Link>
        </LoginApplyBlock>
      )}
    </Wrapper>
  );
};

export default Header;
