import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";

const GlobalStyle = createGlobalStyle`
  ${normalize()};

  html {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.palette.text.default};
    line-height: 1.5;
    background-color: ${({ theme }) => theme.palette.background.default};
    width: 100vw;
  }

  body,  #___gatsby, #gatsby-focus-wrapper  {
    width: 100%;
  }
`;

export default GlobalStyle;
