import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { FormattedMessage } from "react-intl";
import {
  Menu,
  Logo,
  Wrapper,
  Link,
  Links,
  Languages,
  Language,
  LanguageSeparator,
  Button,
} from "./styled";
import Locale from "../../../types/Locale";
import { Image } from "../../../helpers/ImagesContext";
import TimesSolid from "../../SVGs/TimesSolid";
import BarsSolid from "../../SVGs/BarsSolid";
import Img from "../../Img";

interface Props {
  index?: boolean;
  logoImage: Image;
  currentLocale: Locale;
  redirect: (l: Locale) => void;
}

const Mobile: React.FC<Props> = ({
  index,
  logoImage,
  currentLocale,
  redirect,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const hideMenu = () => {
    setShowMenu(false);
  };

  return (
    <Wrapper>
      <Logo href="/">
        <Img data={logoImage} />
      </Logo>
      <Button
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        {showMenu === true && <TimesSolid />}
        {showMenu === false && <BarsSolid />}
      </Button>
      <Menu show={showMenu}>
        <Links>
          <Link
            to={index === true ? "#services" : `/${currentLocale}/#services`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.services" />
          </Link>
          <Link
            to={index === true ? "#values" : `/${currentLocale}/#values`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.values" />
          </Link>
          <Link
            to={index === true ? "#clients" : `/${currentLocale}/#clients`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.clients" />
          </Link>
          <Link
            to={index === true ? "#team" : `/${currentLocale}/#team`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.team" />
          </Link>
          <Link
            to={index === true ? "#about" : `/${currentLocale}/#about`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.about" />
          </Link>
          <Link
            to={index === true ? "#contact" : `/${currentLocale}/#contact`}
            onClick={hideMenu}
          >
            <FormattedMessage id="common.nav.contact" />
          </Link>
        </Links>
        <Languages>
          <Language
            selected={currentLocale === Locale.nl}
            href={`/nl/${location.pathname.substr(4)}${location.hash}`}
            onClick={(e) => {
              e.preventDefault();
              redirect(Locale.nl);
            }}
          >
            NL
          </Language>
          <LanguageSeparator>|</LanguageSeparator>
          <Language
            selected={currentLocale === Locale.fr}
            href={`/fr/${location.pathname.substr(4)}${location.hash}`}
            onClick={(e) => {
              e.preventDefault();
              redirect(Locale.fr);
            }}
          >
            FR
          </Language>
          <LanguageSeparator>|</LanguageSeparator>
          <Language
            selected={currentLocale === Locale.en}
            href={`/en/${location.pathname.substr(4)}${location.hash}`}
            onClick={(e) => {
              e.preventDefault();
              redirect(Locale.en);
            }}
          >
            EN
          </Language>
          <LanguageSeparator>|</LanguageSeparator>
          <Language
            selected={currentLocale === Locale.de}
            href={`/de/${location.pathname.substr(4)}${location.hash}`}
            onClick={(e) => {
              e.preventDefault();
              redirect(Locale.de);
            }}
          >
            DE
          </Language>
        </Languages>
      </Menu>
    </Wrapper>
  );
};

export default Mobile;
