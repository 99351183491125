import React, { useContext } from "react";
import { useNavigate, useLocation } from "@reach/router";
import { useIntl } from "react-intl";
import { unflatten } from "flat";
import Locale from "../../types/Locale";
import ImagesContext from "../../helpers/ImagesContext";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

interface Props {
  index?: boolean;
}

const Nav: React.FC<Props> = ({ index }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const images = useContext(ImagesContext);
  const { messages, locale: currentLocale } = useIntl();
  const content = unflatten(messages);

  const redirect = (locale: Locale) => {
    document.cookie = `nf_lang=${locale};domain=production.be;max-age=31536000;path=/`;
    navigate(`/${locale}/${location.pathname.substr(4)}${location.hash}`);
  };

  const logoImage =
    images.find((i) => content.common.nav.image.src === i.file) || null;

  return (
    <>
      <Mobile
        index={index}
        currentLocale={currentLocale as Locale}
        redirect={redirect}
        logoImage={logoImage}
      />
      <Desktop
        index={index}
        currentLocale={currentLocale as Locale}
        redirect={redirect}
        logoImage={logoImage}
      />
    </>
  );
};

export default Nav;
