import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import linkReset from "../../../helpers/linkReset";

export const Wrapper = styled.nav`
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.unit * 8}px;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.06) 0px 3px 6px 0px;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
    }
  `};
`;

export const Logo = styled.a`
  ${linkReset};
  width: 150px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.xl}px) {
      width: 200px;
    }
  `};
`;

export const Links = styled.div`
  height: 100%;
  align-items: stretch;
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.unit * 4}px;
  margin-left: ${({ theme }) => theme.spacing.unit * 4}px;
`;

export const Languages = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
`;

export const Language = styled.a<{ selected?: boolean }>`
  ${linkReset};
  font-weight: ${({ selected = false }) =>
    selected === true ? "bold" : "normal"};
  color: ${({ theme, selected = false }) =>
    selected === true ? theme.palette.primary.default : "inherit"};
  display: flex;
  height: 100%;
  align-items: center;
`;

export const LanguageSeparator = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.unit}px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Link = styled(GatsbyLink)<{ selected?: boolean }>`
  ${linkReset};
  font-weight: ${({ selected = false }) =>
    selected === true ? "bold" : "normal"};
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    content: "";
    top: calc(100% - 5px);
    left: 0;
    z-index: 6;
    height: 5px;
    width: 100%;
    background-color: ${({ theme, selected = false }) =>
      selected === true ? theme.palette.primary.default : "transparent"};
  }

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.xl}px) {
      margin-right: ${theme.spacing.unit * 4}px;
    }
  `};
`;
